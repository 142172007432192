<div *ngIf="data">
  <app-primary-section>
    <app-secondary-section>
      <app-secondary-table *ngIf="!isEdit">
        <table>
          <thead>
            <tr>
              <th>Информация</th>
              <th>
                <button
                  tuiButton
                  type="button"
                  size="s"
                  class="tui-space_right-3 tui-space_bottom-3 edit-button"
                  icon="tuiIconEdit"
                  (click)="onEdit()"
                >
                  Изменить
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Название</td>
              <td>{{ data.name }}</td>
            </tr>
            <tr>
              <td>Месторождение</td>
              <td>{{ data.parent?.name || "Не выбрано" }}</td>
            </tr>
            <tr>
              <td>Куст</td>
              <td>{{ data.bush_parent?.name || "Не выбран" }}</td>
            </tr>
            <tr>
              <td>Объект нефтесбора</td>
              <td>{{ data.oil_parent?.name || "Не выбран" }}</td>
            </tr>
            <tr>
              <td>Широта</td>
              <td>
                {{ memoizedGetCurrentLat(data.latitude, data.longitude) }}
              </td>
            </tr>
            <tr>
              <td>Долгота</td>
              <td>
                {{ memoizedGetCurrentLon(data.latitude, data.longitude) }}
              </td>
            </tr>
            <tr>
              <td>Тип добычи</td>
              <td>{{ getTypeMining(data.attributes?.type_mining) }}</td>
            </tr>
            <tr>
              <td>Режимный дебит</td>
              <td>
                {{
                  getDebit(data?.attributes?.debit, data?.debit_device_field)
                }}
              </td>
            </tr>
            <tr>
              <td>Обводнённость</td>
              <td>{{ data.attributes?.water_cut || "Не выбрана" }}</td>
            </tr>

            <tr>
              <td>Нефтепровод</td>
              <td>{{ findNameByIdFromArray(data.oil_pipe_id, oilPipelines) || "Не выбран" }}</td>
            </tr>

            <tr>
              <td>Водопровод</td>
              <td>{{ findNameByIdFromArray(data.water_pipe_id, waterPipelines) || "Не выбран" }}</td>
            </tr>

            <tr>
              <td>Время работы</td>
              <td>{{ getWorkTime(data.work_time) }}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>Датчики</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Датчик статуса</td>
              <td *ngIf="!data.status_device_id">Не выбран</td>
              <td *ngIf="data.status_device_id">
                {{ data.status_device?.name || "-" }}
                <tui-badge
                  class="status"
                  [value]="statusTitles[data.status] || '-'"
                  status="default"
                >
                </tui-badge>
              </td>
            </tr>
            <tr>
              <td>Датчик давления текущего объекта нефтесбора</td>
              <td>
                {{ data.curr_pressure?.name || "Не выбран" }}
                <tui-badge
                  *ngIf="data.curr_pressure"
                  [status]="'primary'"
                  class="status"
                  [value]="currentPressureValue"
                ></tui-badge>
              </td>
            </tr>
            <tr>
              <td>Датчик давления следующего объекта нефтесбора</td>
              <td>
                {{ data.next_pressure?.name || "Не выбран" }}
                <tui-badge
                  *ngIf="data.next_pressure"
                  [status]="'primary'"
                  class="status"
                  [value]="nextPressureValue"
                ></tui-badge>
              </td>
            </tr>
            <tr>
              <td>Датчик частоты</td>
              <td *ngIf="!data.freq_device">Не выбран</td>
              <td *ngIf="data.freq_device">
                {{ data.freq_device?.name || "-" }}
                <tui-badge
                  *ngIf="data.freq_device?.last_message && data.freq_device?.last_message[data.freq_device_field]"
                  [status]="'primary'"
                  class="status"
                  [value]="getFreq(data)"
                ></tui-badge>
              </td>
            </tr>
            <tr>
              <td>Датчик дебита</td>
              <td>
                {{ getDevicesName() }}
                <tui-badge
                  *ngIf="data.debit_device_value !== null"
                  [status]="'primary'"
                  class="status"
                  [value]="
                    getDebitUnitDebitDevice(
                      data.debit_device_value,
                      data.debit_device_field
                    )
                  "
                ></tui-badge>
              </td>
            </tr>
          </tbody>
        </table>
      </app-secondary-table>
      <app-secondary-table *ngIf="isEdit">
        <form [formGroup]="infoFormGroup">
          <div class="tables">
            <table>
              <thead>
                <tr>
                  <th>Информация</th>
                  <th>
                    <button
                      tuiButton
                      type="button"
                      size="s"
                      class="tui-space_right-3 tui-space_bottom-3 edit-button"
                      (click)="onSave()"
                    >
                      <i-tabler name="device-floppy"></i-tabler>
                      Сохранить
                    </button>
                    <button
                      tuiButton
                      type="button"
                      size="s"
                      class="tui-space_right-3 tui-space_bottom-3 edit-button"
                      icon="tuiIconEdit"
                      (click)="onEdit()"
                    >
                      Отмена
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="form-field-title">Название *</td>
                  <td>
                    <tui-input
                      tuiTextfieldSize="m"
                      formControlName="name"
                      [tuiTextfieldMaxLength]="10"
                      [tuiTextfieldLabelOutside]="true"
                    >
                      Введите название скважины
                      <input tuiTextfield type="text" />
                    </tui-input>
                    <tui-field-error formControlName="name"></tui-field-error>
                  </td>
                </tr>
                <tr>
                  <td class="form-field-title">Месторождение *</td>
                  <td>
                    <tui-select
                      *tuiLet="parentsList as items"
                      class="b-form"
                      [tuiTextfieldLabelOutside]="true"
                      [valueContent]="items ? stringify(items) : loading"
                      formControlName="parent_id"
                      tuiTextfieldSize="m"
                    >
                      <ng-template tuiDataList>
                        <tui-data-list *ngIf="items; else loading">
                          <tui-opt-group>
                            <button
                              *ngFor="let item of items"
                              tuiOption
                              [value]="item.id"
                            >
                              {{ item.name }}
                            </button>
                          </tui-opt-group>
                        </tui-data-list>
                      </ng-template>
                      <ng-template #loading>
                        <tui-loader
                          class="tui-space_vertical-3 loader"
                        ></tui-loader>
                      </ng-template>
                    </tui-select>
                    <tui-field-error
                      formControlName="parent_id"
                    ></tui-field-error>
                  </td>
                </tr>
                <tr>
                  <td class="form-field-title">Куст</td>
                  <td>
                    <tui-select
                      *tuiLet="filterAvailableBushes as items"
                      class="b-form"
                      [tuiTextfieldLabelOutside]="true"
                      [valueContent]="
                        !skeletonVisible ? stringify(items) : loading
                      "
                      formControlName="bush_parent_id"
                      tuiTextfieldSize="m"
                    >
                      <span *ngIf="!disabledBushField; else disabled_bush_field"
                        >Выберите куст</span
                      >
                      <ng-template #disabled_bush_field
                        >Нет элементов для выбора
                      </ng-template>
                      <ng-template tuiDataList>
                        <tui-data-list
                          *ngIf="!skeletonVisible && items; else loading"
                        >
                          <tui-opt-group>
                            <button
                              *ngFor="let item of items"
                              tuiOption
                              [value]="item.id"
                            >
                              {{ item.name }}
                            </button>
                          </tui-opt-group>
                        </tui-data-list>
                      </ng-template>
                      <ng-template #loading>
                        <tui-loader
                          class="tui-space_vertical-3 loader"
                        ></tui-loader>
                      </ng-template>
                    </tui-select>
                    <tui-field-error
                      formControlName="bush_parent_id"
                    ></tui-field-error>
                  </td>
                </tr>
                <tr>
                  <td class="form-field-title">Объект нефтесбора</td>
                  <td>
                    <tui-select
                      *tuiLet="getConcatOils() as items"
                      class="b-form"
                      [tuiTextfieldLabelOutside]="true"
                      [valueContent]="
                        !skeletonVisible ? stringify(items) : loading
                      "
                      formControlName="oil_parent_id"
                      tuiTextfieldSize="m"
                    >
                      <span *ngIf="!disabledOilField; else disabled_oil_field"
                        >Выберите объект нефтесбора</span
                      >
                      <ng-template #disabled_oil_field
                        >Нет элементов для выбора
                      </ng-template>
                      <ng-template tuiDataList>
                        <tui-data-list
                          *ngIf="!skeletonVisible && items; else loading"
                        >
                          <tui-opt-group label="ДНС">
                            <button
                              *ngFor="let item of listOfOil.dnsList"
                              tuiOption
                              [value]="item.id"
                            >
                              {{ item.name }}
                            </button>
                          </tui-opt-group>
                          <tui-opt-group label="ГЗУ">
                            <button
                              *ngFor="let item of listOfOil.gzuList"
                              tuiOption
                              [value]="item.id"
                            >
                              {{ item.name }}
                            </button>
                          </tui-opt-group>
                          <tui-opt-group label="БГ/УЗА">
                            <button
                              *ngFor="let item of listOfOil.bgList"
                              tuiOption
                              [value]="item.id"
                            >
                              {{ item.name }}
                            </button>
                          </tui-opt-group>
                        </tui-data-list>
                      </ng-template>
                      <ng-template #loading>
                        <tui-loader
                          class="tui-space_vertical-3 loader"
                        ></tui-loader>
                      </ng-template>
                    </tui-select>
                    <tui-field-error
                      formControlName="oil_parent_id"
                    ></tui-field-error>
                  </td>
                </tr>
                <tr>
                  <td class="form-field-title">Широта *</td>
                  <td>
                    <tui-input-number
                      [precision]="14"
                      tuiTextfieldSize="m"
                      formControlName="latitude"
                    >
                      Введите широту
                    </tui-input-number>
                    <tui-field-error
                      formControlName="latitude"
                    ></tui-field-error>
                  </td>
                </tr>
                <tr>
                  <td class="form-field-title">Долгота *</td>
                  <td>
                    <tui-input-number
                      [precision]="14"
                      tuiTextfieldSize="m"
                      formControlName="longitude"
                    >
                      Введите долготу
                    </tui-input-number>
                    <tui-field-error
                      formControlName="longitude"
                    ></tui-field-error>
                  </td>
                </tr>
                <tr>
                  <td class="form-field-title">Тип добычи *</td>
                  <td>
                    <tui-select
                      *tuiLet="availableTypeMining as items"
                      class="b-form"
                      [tuiTextfieldLabelOutside]="true"
                      [valueContent]="items ? stringify(items) : loading"
                      formControlName="type_mining"
                      tuiTextfieldSize="m"
                    >
                      <ng-template tuiDataList>
                        <tui-data-list *ngIf="items; else loading">
                          <button
                            *ngFor="let item of items"
                            tuiOption
                            [value]="item.id"
                          >
                            {{ item.name }}
                          </button>
                        </tui-data-list>
                      </ng-template>
                      <ng-template #loading>
                        <tui-loader
                          class="tui-space_vertical-3 loader"
                        ></tui-loader>
                      </ng-template>
                    </tui-select>
                    <tui-field-error
                      formControlName="type_mining"
                    ></tui-field-error>
                  </td>
                </tr>
                <tr>
                  <td class="form-field-title">Режимный дебит</td>
                  <td>
                    <tui-input-number
                      tuiTextfieldSize="m"
                      formControlName="debit"
                    >
                      Введите режимный дебит
                    </tui-input-number>
                    <tui-field-error formControlName="debit"></tui-field-error>
                  </td>
                </tr>

                <tr>
                  <td class="form-field-title">Обводнённость</td>
                  <td>
                    <tui-input-number
                      tuiTextfieldSize="m"
                      formControlName="water_cut"
                    >
                      Введите обводнённость
                    </tui-input-number>
                    <tui-field-error
                      formControlName="water_cut"
                    ></tui-field-error>
                  </td>
                </tr>

                <tr>
                  <td class="form-field-title">Нефтепровод</td>
                  <td>

                    <app-form-select-with-search-for-huge-arrays
                      [form]="infoFormGroup"
                      [formControlNameSelect]="'oil_pipe'"
                      [label]="'Выберите нефтепровод'"
                      [placeholderMessage]="'Введите название нефтепровода...'"
                      [visualRequired]="false"
                      [items]="oilPipelines"
                      style="width: 100%"
                    >
                    </app-form-select-with-search-for-huge-arrays>

                    <tui-field-error
                      formControlName="oil_pipe"
                    ></tui-field-error>
                  </td>
                </tr>

                <tr>
                  <td class="form-field-title">Водопровод</td>
                  <td>

                    <app-form-select-with-search-for-huge-arrays
                      [form]="infoFormGroup"
                      [formControlNameSelect]="'water_pipe'"
                      [label]="'Выберите водопровод'"
                      [placeholderMessage]="'Введите название водопровода...'"
                      [visualRequired]="false"
                      [items]="waterPipelines"
                      style="width: 100%"
                    >
                    </app-form-select-with-search-for-huge-arrays>

                    <tui-field-error
                      formControlName="water_pipe"
                    ></tui-field-error>
                  </td>
                </tr>

              </tbody>
            </table>
            <table>
              <thead>
                <tr>
                  <th>Датчики</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="form-field-title">Датчик статуса</td>
                  <td>
                    <ng-container>

                      <app-form-select-with-search-for-huge-arrays
                        [form]="infoFormGroup"
                        [formControlNameSelect]="'status_device_id'"
                        [label]="'Выберите датчик статуса'"
                        [placeholderMessage]="'Введите название датчика...'"
                        [visualRequired]="false"
                        [items]="availableStatusDevices"
                        style="width: 100%"
                      >
                      </app-form-select-with-search-for-huge-arrays>

                      <tui-field-error
                        formControlName="status_device_id"
                      ></tui-field-error>
                    </ng-container>
                  </td>
                </tr>
                <tr>
                  <td class="form-field-title">Датчик давления текущего объекта нефтесбора</td>
                  <td>
                    <ng-container>

                      <app-form-select-with-search-for-huge-arrays
                        [form]="infoFormGroup"
                        [formControlNameSelect]="'curr_pressure'"
                        [label]="'Выберите датчик давления'"
                        [placeholderMessage]="'Введите название датчика...'"
                        [visualRequired]="false"
                        [items]="availablePressures"
                        style="width: 100%"
                      >
                      </app-form-select-with-search-for-huge-arrays>

                      <tui-field-error
                        formControlName="curr_pressure"
                      ></tui-field-error>
                    </ng-container>
                  </td>
                </tr>

                <tr>
                  <td class="form-field-title">Датчик давления следующего объекта нефтесбора</td>
                  <td>
                    <ng-container>

                      <app-form-select-with-search-for-huge-arrays
                        [form]="infoFormGroup"
                        [formControlNameSelect]="'next_pressure'"
                        [label]="'Выберите датчик давления'"
                        [placeholderMessage]="'Введите название датчика...'"
                        [visualRequired]="false"
                        [items]="availablePressures"
                        style="width: 100%"
                      >
                      </app-form-select-with-search-for-huge-arrays>

                      <tui-field-error
                        formControlName="next_pressure"
                      ></tui-field-error>
                    </ng-container>
                  </td>
                </tr>
                <tr>
                  <td class="form-field-title">Датчик частоты</td>
                  <td>
                    <div class="row-fields-two">
                      <div>
                        <div>

                          <app-form-select-with-search-for-huge-arrays
                            [form]="infoFormGroup"
                            [formControlNameSelect]="'freq_device_id'"
                            [label]="'Выберите датчик частоты'"
                            [placeholderMessage]="'Введите название датчика...'"
                            [visualRequired]="false"
                            [items]="availableStatusDevices"
                          >
                          </app-form-select-with-search-for-huge-arrays>

                          <tui-field-error
                            formControlName="freq_device_id"
                          ></tui-field-error>
                        </div>
                      </div>
                      <div class="input">
                        <tui-select
                          *tuiLet="availableFreqFields as items"
                          class="b-form"
                          [tuiTextfieldLabelOutside]="false"
                          [valueContent]="items ? stringify(items) : loading"
                          [class.tui-skeleton]="skeletonVisible"
                          [class.tui-skeleton_rounded]="skeletonVisible"
                          formControlName="freq_device_field"
                          tuiTextfieldSize="m"
                        >
                          Параметр устройства
                          <ng-template tuiDataList>
                            <tui-data-list *ngIf="items; else loading">
                              <button
                                *ngFor="let item of items"
                                tuiOption
                                [value]="item.id"
                              >
                                {{ item.name }}
                              </button>
                            </tui-data-list>
                          </ng-template>
                          <ng-template #loading>
                            <tui-loader
                              class="tui-space_vertical-3 loader"
                            ></tui-loader>
                          </ng-template>
                        </tui-select>
                        <span
                          class="error"
                          *ngIf="
                            checkStatusFreg() &&
                            infoFormGroup.get('freq_device_field')?.touched
                          "
                        >
                          Поле обязательно для заполнения</span
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="form-field-title">Датчик дебита</td>
                  <td class="device-container">
                    <div
                      formArrayName="debits_params_devices"
                      *ngFor="
                        let section of memoizedGetSections(infoFormGroup);
                        let i = index
                      "
                      class="device-row-container"
                    >
                      <div [ngClass]="{'device-row': i === 0 && memoizedGetSections(infoFormGroup).length < 2,
                      'device-row__first-control': i === 0 && memoizedGetSections(infoFormGroup).length > 1,
                      'device-row__second-control': i === 1 && memoizedGetSections(infoFormGroup).length > 1}"
                           [formGroupName]="i">
                          <button
                            *ngIf="i === 0 && memoizedGetSections(infoFormGroup).length < 2"
                            class="add-button"
                            tuiIconButton
                            type="button"
                            icon="tuiIconPlus"
                            appearance="iconHovered"
                            size="s"
                            (click)="add()"
                          ></button>
                        <div
                          [class]="
                            i > 0 ? 'debit_device_multi' : 'debit_device'
                          "
                        >

                            <tui-combo-box
                              [class.tui-skeleton]="skeletonVisible"
                              [class.tui-skeleton_rounded]="skeletonVisible"
                              *tuiLet="availableDebitDevices$ | async as items"
                              tuiTextfieldSize="m"
                              class="tui-space_vertical-5"
                              formControlName="debit_device_id"
                              [stringify]="stringifyComboBox"
                              [valueContent]="
                                skeletonVisible ? loading : statusSelectContent
                              "
                            >
                              Выберите датчик дебита
                              <input
                                tuiTextfield
                                placeholder="Введите название датчика..."
                                (input)="
                                  onSearchChange(
                                    extractValueFromEvent($event),
                                    'debit_device_id'
                                  )
                                "
                              />

                              <tui-data-list-wrapper
                                *tuiDataList
                                [items]="items"
                                [itemContent]="statusSelectContent"
                              ></tui-data-list-wrapper>
                            </tui-combo-box>

                        </div>
                          <button
                            *ngIf="i === 0 && memoizedGetSections(infoFormGroup).length > 1"
                            class="add-button_toggle"
                            tuiIconButton
                            type="button"
                            [icon]="iconBetweenDebits"
                            appearance="iconHovered"
                            size="s"
                            [tuiHint]="'Кликнув, Вы смените знак арифметического действия ' +
                             'на противоположный, что означает сложение или вычетание предыдущего параметра датчика с ' +
                              'последующим'"
                            (click)="changeIconBetweenDebits()"
                          ></button>
                        <div class="debit_parameters">
                          <tui-select
                            [class.tui-skeleton]="skeletonVisible"
                            [class.tui-skeleton_rounded]="skeletonVisible"
                            *tuiLet="availableDebitFields as items"
                            class="b-form"
                            [tuiTextfieldLabelOutside]="false"
                            [valueContent]="items ? stringify(items) : loading"
                            formControlName="debit_device_field"
                            tuiTextfieldSize="m"
                          >
                            Параметр устройства
                            <ng-template tuiDataList>
                              <tui-data-list *ngIf="items; else loading">
                                <button
                                  *ngFor="let item of items"
                                  tuiOption
                                  [value]="item.id"
                                >
                                  {{ item.name }}
                                </button>
                              </tui-data-list>
                            </ng-template>
                            <ng-template #loading>
                              <tui-loader
                                class="tui-space_vertical-3 loader"
                              ></tui-loader>
                            </ng-template>
                          </tui-select>

                        </div>

                          <button
                            *ngIf="
                              memoizedGetSections(infoFormGroup).length > 1
                            "
                            class="delete-button"
                            tuiIconButton
                            type="button"
                            icon="tuiIconCloseLarge"
                            appearance="iconHovered"
                            size="s"
                            (click)="remove(i)"
                          ></button>
                      </div>

                    </div>
                    <tui-error
                      *ngIf="debitSensorErrorMessage"
                      class="debit_device_error"
                      error="{{ debitSensorErrorMessage }}"
                    ></tui-error>
                  </td>
                  <hr />
                </tr>
              </tbody>
            </table>
          </div>
          <span class="form-footer">* Обязательные для заполнения поля</span>
        </form>
      </app-secondary-table>
    </app-secondary-section>
  </app-primary-section>

  <app-primary-section
    *ngIf="(data?.latitude !== null && data?.longitude !== null) || isEdit"
  >
    <app-borehole-card-map
      *ngIf="
        (data.latitude < 90 &&
          data.latitude > -90 &&
          data.longitude < 180 &&
          data.longitude > -180) ||
          isEdit;
        else unavailableCoords
      "
      [onClickOnMap]="onClickOnMap"
      [data]="data"
      [isEdit]="isEdit"
      [latitude]="latitude"
      [longitude]="longitude"
      [visibleMap]="visibleMap"
    ></app-borehole-card-map>
    <ng-template #unavailableCoords>
      <div class="unavailable-map">
        <span class="unavailable-map__text">
          Точка на карте расположена вне области видимости карты, измените
          координаты в соответствии с допустимыми значениями, а именно: значения
          широты должны быть менее 90, но более -90, значения долготы должны
          быть менее 180, но более -180, чтобы отобразить точку на карте
        </span>
      </div>
    </ng-template>
  </app-primary-section>
  <ng-template #loading>
    <tui-loader class="tui-space_vertical-3 loader"></tui-loader>
  </ng-template>
  <ng-template #statusSelectContent let-data>
    <div class="template">
      <div class="name">{{ data.name }}</div>
    </div>
  </ng-template>
</div>
<div *ngIf="!data" class="common-info__no-content">Выберите объект</div>
